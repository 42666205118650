<template>

    <div class="product-view">
        <ecommerce-hero
                title="Your Ultimate Smartphone"
                :background="heroBackground">

        </ecommerce-hero>
        <div class="uk-container-large uk-margin-auto uk-padding-small">
            <ul class="uk-breadcrumb">
                <li><a href="#shop">Shop</a></li>
                <li><a @click="$router.push({name: 'ecommerce-category', params: {'categoryAlias': 'devices'}})">Devices</a></li>
                <li class="uk-disabled"><a>Apple iPhone 12 Pro</a></li>
            </ul>
        </div>

        <div class="uk-container-large uk-margin-auto uk-margin-large-bottom uk-padding-small">

            <h1 aria-live="assertive">Apple iPhone 12 PRO</h1>
            <div class="uk-flex product content" >
                    <div class="product-gallery uk-width-1-3 uk-margin-large-right">
                        <div uk-slideshow="animation: push">

                            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

                                <ul class="uk-slideshow-items">
                                    <li v-for="(image, index) of product.images" :key="image">
                                        <img :src="image.src" alt="" uk-cover>
                                    </li>
                                </ul>

                                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
                                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

                                <ul class="uk-flex product-slideshow-nav uk-thumbnav uk-flex-center uk-margin">
                                    <li class="uk-flex" :uk-slideshow-item="index" v-for="(image, index) of product.images" :key="image">
                                        <a href="#" class="uk-position-relative" :style="sourceToBackgroundCss(image.thumbSrc)"></a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="product-primary-content">
                        <div class="product-details">
                            <h3>Apple's flagship device.</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A iure maxime sint unde! Maxime officiis possimus unde vel. Adipisci assumenda, dignissimos exercitationem explicabo facere fuga nihil possimus quo recusandae. Maxime?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A iure maxime sint unde! Maxime officiis possimus unde vel. Adipisci assumenda, dignissimos exercitationem explicabo facere fuga nihil possimus quo recusandae. Maxime?</p>

                        </div>

                        <div class="product-highlights uk-margin-small-bottom uk-flex uk-flex-wrap uk-flex-middle uk-flex-between">
                            <div class="highlight-content uk-margin-right">
                                <h4 class="uk-text-primary uk-margin-remove-bottom">Why Should I Get Apple iPhone 12 PRO?</h4>
                                <ul class="uk-list uk-list-square uk-margin-remove-top">
                                    <li>
                                        <span>Flagship device</span>
                                    </li>
                                    <li>
                                        <span>Best camera on the market</span>
                                    </li>
                                    <li>
                                        <span>Special price @ VFP</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="product-add uk-container uk-container-small uk-margin-remove uk-background-muted uk-padding">
                                <div class="product-options uk-flex uk-flex-between uk-flex-top">
                                    <base-form
                                            action="/session"
                                            method="post"
                                            class="uk-margin-remove"
                                            :field-wrapper-margin="false"
                                            formPath="form/ecommerce-productBuilderDeviceDemo"
                                            :expectedFields="3"
                                            v-model="addToCart.options"
                                    ></base-form>

                                </div>


                                <div class="product-cta uk-flex uk-flex-wrap">
                                    <div class="add-input uk-margin-small-right uk-flex uk-flex-middle">
                                        <form-input
                                                name="qty"
                                                type="units"
                                                class="uk-margin-medium-right uk-margin-small-bottom"
                                                minimum="1"
                                                v-model="addToCart.qty"
                                                :wrapperMargins="false"
                                        >

                                        </form-input>
                                        <div class="product-prices uk-flex uk-flex-middle uk-flex-bottom uk-margin-small-bottom ">
                                            <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">2 233 F</div>
                                            <div class="old-price ">2 500 F</div>
                                        </div>
                                    </div>

                                    <form-button class="uk-margin-small-right"
                                                 :fullWidth="true"
                                                 icon="cart"
                                                @click="$router.push({name:'builder-demo'})">ADD TO CART</form-button>
                                </div>

                            </div>
                        </div>




                    </div>
                </div>
            <div class="product-extra-info">
                <div class="subscription-actions-content">
                    <ul class="uk-subnav uk-subnav-pill uk-margin-remove-bottom" uk-tab>
                        <li><a href="#">Technical Specification</a></li>
                        <li><a href="#">Product Video</a></li>
                        <li><a href="#">Custom</a></li>
                    </ul>

                    <div class=" product-extra-info-switcher uk-switcher uk-margin uk-margin-remove-top uk-overflow-hidden uk-padding-small">
                        <div class="uk-overflow-auto">
                            <table class="uk-table uk-table-striped">
                                <caption>Technical Specification for thr iPhone 12 PRO</caption>
                                <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Value</th>
                                    <th>Extra</th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr>
                                    <td>Memory</td>
                                    <td>32GB / 64GB / 128GB</td>
                                    <td>Also available @ 256GB</td>
                                </tr>

                                <tr>
                                    <td>Color</td>
                                    <td>Black, Mate, Red</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Screen Size</td>
                                    <td>6"</td>
                                    <td>Pro X model - 7"</td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Some State</td>
                                    <td>value</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <iframe style="width: 100%;height:70vh;" src="https://www.youtube.com/embed/cnXapYkboRQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div>
                            Even more content
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>


</template>

<script>
    import ecommerceHero from "@/client/components/ecommerce/Hero.vue";
    import asyncOperations from "@/client/extensions/composition/asyncOperations";

    export default {
        props: {
            productAlias: {
                type: [String, Boolean],
                default: 'something-something'
            }
        },
        components: {
            ecommerceHero
        },
        data: function () {
            return {
                addToCart: {
                    qty: 1,
                    options: {}
                },
                subscription: {
                    showGallery: false,
                    images: []
                },
                phone: {
                    showGallery: true,
                    images: [
                        {
                            src: 'images/products/iphone12_1.jpg',
                            thumbSrc: 'images/products/iphone12_1.jpg',
                        },
                        {
                            src: 'images/products/iphone12_2.jpg',
                            thumbSrc: 'images/products/iphone12_2.jpg',
                        },
                        {
                            src: 'images/products/iphone12_3.jpg',
                            thumbSrc: 'images/products/iphone12_3.jpg',
                        },
                    ],
                }
            };
        },
        computed: {
            product() {
                if (this.productAlias === 'subscription') {
                    return this.subscription;
                } else {
                    return this.phone;
                }
            }

        },
        methods: {
            sourceToBackgroundCss (src) {
                return "background-image: url('"+src+"');"
            }
        },
        mounted () {

        },
    }
</script>

<style scoped lang="scss">


    .product-slideshow-nav {
        li {

            a {
                height: 50px;
                width: 70px;
                display: inline-block;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .old-price {
        text-decoration: line-through;
    }

    .product-extra-info-switcher {
        border: 1px solid var(--global-border);
        border-top: none;
    }
</style>
